/* You can add global styles to this file, and also import other style files */

// @import './assets/scss/layout/version-dark/_main.scss';
// @import "./assets/vendor/bootstrap/scss/bootstrap.scss";
@import "./assets/css/style.css";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100&display=swap");
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
// regular style toast
@import "~ngx-toastr/toastr";
@import "../node_modules/quill/dist/quill.bubble.css";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";


.toast-container .ngx-toastr {
  display: block;
}
.carousel-control{
  display: none;
}

.carousel-indicators li {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.login-bg {
  background: url("assets/images/Background-06.jpg") no-repeat 0, 0;
  background-size: cover;
  background-position: center center;
  height: 100%;
  position: relative;
}

.authincation {
  /* fallback for old browsers */
  //   background: #758bea;
  /* Chrome 10-25, Safari 5.1-6 */
  //   background: -webkit-linear-gradient(
  //     to bottom,
  //     rgba(97 127 255 / 70%),
  //     rgba(0, 86, 245, 0.7)
  //   );
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  //   background: linear-gradient(
  //     to bottom,
  //     rgba(97 127 255 / 70%),
  //     rgba(0, 86, 245, 0.7)
  //   );
  color: black;
  .container {
    // float: right;
    .authincation-content {
      color: black;
      background: #ffffff !important;
      // .facebook-button.dark{
      //     width: 100% !important;
      //     text-align: left !important;
      //     margin: 5 !important;
      //     padding-left: 10px !important;
      //     background-color: #5378d3 !important;
      //     border: 1px solid #567bd6 !important;
      //     color: #fff !important;
      //     .icon{
      //         border-radius: 50% !important;
      //         margin: 5px !important;
      //     }
      // }
    }
  }
  .not-found {
    float: none !important;
  }
}

.error-container {
  background: #396ce5;
  color: white;
  h1,
  h4 {
    color: white !important;
  }
}

.form-control {
  color: #828690;
}

[data-theme-version="dark"] {
  .form-control {
    background-color: transparent;
    border-color: #5b5289;
    color: #828690;
  }
  .has-feedback {
    .form-control {
      padding-left: 3.375rem;
    }
    .form-control-feedback {
      font-size: x-large;
      margin-top: 3px;
      position: absolute;
      z-index: 2;
      display: block;
      width: 2.375rem;
      height: 2.375rem;
      line-height: 2.375rem;
      text-align: center;
      pointer-events: none;
      color: #aaa;
    }
  }
}

// [data-theme-version="dark"] {
//   background: none;
//   color: white;
// }
.custom-switches {
  .switch {
    background: #fb6542;
    small {
      background: #375e97;
    }
    &.checked {
      background: #3f681c;
    }
  }
}

.custom-switches {
  .switch {
    background: #fb6542 !important;
    small {
      background: #fff !important;
    }
    &.checked {
      background: rgb(100, 189, 99) !important;
    }
  }
}
// .facebook-button.dark{
//     width: 100% !important;
//     text-align: left !important;
//     margin: 5 !important;
//     padding-left: 10px !important;
//     background-color: #5378d3 !important;
//     border: 1px solid #567bd6 !important;
//     color: #fff !important;
//     .icon{
//         border-radius: 50% !important;
//         margin: 5px !important;
//     }
//   }

// Background Colors
.bg-blue-300 {
  background-color: #83ccf9 !important;
}
.bg-blue-500 {
  background-color: #3b9edf !important;
}
.bg-red-400 {
  background-color: #fa9a82 !important;
}
.bg-green-300 {
  background-color: #9ecd5d !important;
}
.bg-green-500 {
  background-color: #66a60d !important;
}
.bg-orange-500 {
  background-color: #f9be4a !important;
}

.text-color-white {
  color: #ffffff !important;
}
.ql-tooltip {
  z-index: 9999;
}
// .ql-tooltip.ql-editing {
//   z-index: 99999;
// }

[data-theme-version="dark"] .nav-tabs .nav-link:hover, [data-theme-version="dark"] .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 4px solid;
  border-color: #4a68da;
}
[data-theme-version="dark"] .nav-tabs {
  border: none !important;
}
.font-size-half {
  font-size: .7em;
}
.userButton{
  padding: 0.625rem 1rem;
  font-size: 0.813rem;
}
.actionTitle {
  display: flex;
  align-items: flex-start;
  justify-content: center !important;
  padding: 1rem 1rem;
  border-bottom: 1px solid #EEEEEE;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }